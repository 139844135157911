import { DropdownMenu as dm } from "@kobalte/core";
import type { IconTypes } from "solid-icons";
import { TbChevronRight } from "solid-icons/tb";
import { type Component, For, type JSXElement, Match, type ParentComponent, type Setter, Show, Switch } from "solid-js";
import { twMerge } from "tailwind-merge";
import { StIcon } from "@core/components/icons";
import { COLOR_THEME_MANAGER, type ComponentTheme } from "@core/lib/theme";
import { GLOBAL_ELEMENT_IDS } from "@core/ui/global-element-ids";
import { HorizontalDivider } from "./HorizontalDivider";

type Item = {
  kind: "item";
  content: JSXElement;
  props?: dm.DropdownMenuItemProps;
  icon?: IconTypes;
};
type Separator = {
  kind: "separator";
};
type SubMenu = {
  kind: "menu";
  trigger: Omit<Item, "kind">;
  items: StDropdownMenuItem[];
};
type Group = {
  kind: "group";
  label: string;
  items: StDropdownMenuItem[];
};
export type StDropdownMenuItem = Item | Separator | SubMenu | Group;

export const StDropdown: ParentComponent<{
  theme: ComponentTheme;
  opts?: dm.DropdownMenuRootProps;
  trigger?: dm.DropdownMenuTriggerProps;
  content?: dm.DropdownMenuContentProps;
  containerClass?: string;
  mount?: HTMLElement;
  items: StDropdownMenuItem[];
  setRef?: Setter<HTMLDivElement | undefined>;
}> = (props) => {
  const themeClass = () => {
    if (props.theme === "default") return;
    if (props.theme === "light" || props.theme === "dark") return props.theme;
    if (props.theme === "invert") return COLOR_THEME_MANAGER.activeTheme === "light" ? "dark" : "light";
  };
  return (
    <dm.Root {...props.opts}>
      <dm.Trigger {...props.trigger}>{props.children}</dm.Trigger>
      <dm.Portal
        mount={props.mount ?? document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}
        ref={props.setRef}
      >
        <dm.Content
          {...props.content}
          class={twMerge(
            themeClass(),
            "min-w-56 p-2 rounded-md border shadow-lg origin-kb animate-kb-hide kb-expanded:animate-kb-show outline-none z-[600]",
            "bg-surface border-background-decoration",
            props.content?.class,
          )}
        >
          <div class={props.containerClass}>
            <For each={props.items}>
              {(item) => {
                return <StDropdownItem theme={props.theme} item={item} mount={props.mount} content={props.content} />;
              }}
            </For>
          </div>
          <dm.Arrow />
        </dm.Content>
      </dm.Portal>
    </dm.Root>
  );
};

const StDropdownItem: Component<{
  item: StDropdownMenuItem;
  mount?: HTMLElement;
  content?: dm.DropdownMenuContentProps;
  theme: ComponentTheme;
}> = (props) => {
  const themeClass = () => {
    if (props.theme === "default") return;
    if (props.theme === "light" || props.theme === "dark") return props.theme;
    if (props.theme === "invert") return COLOR_THEME_MANAGER.activeTheme === "light" ? "dark" : "light";
  };

  return (
    <Switch>
      <Match when={props.item.kind === "item"}>
        <dm.Item
          {...(props.item as Item).props}
          class={twMerge(
            "flex items-center text-sm rounded-md h-8 px-7 relative select-none outline-none kb-disabled:opacity-50 kb-disabled:pointer-events-none",
            "text-on-surface kb-expanded:bg-primary kb-expanded:text-on-primary kb-highlighted:bg-primary kb-highlighted:text-on-primary",
            (props.item as Item).icon && "pl-1",
            (props.item as Item).props?.class,
          )}
        >
          <Show when={(props.item as Item).icon}>
            <StIcon icon={(props.item as Item).icon!} class="mr-2" />
          </Show>
          {(props.item as Item).content}
        </dm.Item>
      </Match>
      <Match when={props.item.kind === "separator"}>
        <HorizontalDivider class="w-full my-2" />
      </Match>
      <Match when={props.item.kind === "menu"}>
        <dm.Sub overlap shift={-8} gutter={12}>
          <dm.SubTrigger
            {...(props.item as SubMenu).trigger.props}
            class={twMerge(
              themeClass(),
              "flex items-center rounded-md text-sm h-8 pr-2 pl-7 relative select-none outline-none kb-disabled:opacity-50 kb-disabled:pointer-events-none",
              "text-on-surface kb-expanded:bg-primary kb-expanded:text-on-primary kb-highlighted:bg-primary kb-highlighted:text-on-primary",
              (props.item as SubMenu).trigger.icon && "pl-1",
              (props.item as SubMenu).trigger.props?.class,
            )}
          >
            <Show when={(props.item as SubMenu).trigger.icon}>
              <StIcon icon={(props.item as SubMenu).trigger.icon!} class="mr-2" />
            </Show>
            <div class="flex items-center flex-auto">{(props.item as SubMenu).trigger.content}</div>
            <StIcon icon={TbChevronRight} />
          </dm.SubTrigger>

          <dm.Portal mount={props.mount}>
            <dm.SubContent
              {...props.content}
              class={twMerge(
                themeClass(),
                props.content?.class,
                "min-w-56 p-2 rounded-md border shadow-lg origin-kb animate-kb-hide kb-expanded:animate-kb-show outline-none z-[400]",
                "bg-surface border-background-decoration",
              )}
            >
              <For each={(props.item as SubMenu).items}>
                {(item) => (
                  <StDropdownItem theme={props.theme} item={item} mount={props.mount} content={props.content} />
                )}
              </For>
            </dm.SubContent>
          </dm.Portal>
        </dm.Sub>
      </Match>
      <Match when={props.item.kind === "group"}>
        <dm.Group>
          <dm.GroupLabel class={twMerge("px-2 text-xs leading-8 select-none", "text-on-surface")}>
            {(props.item as Group).label}
          </dm.GroupLabel>

          <For each={(props.item as Group).items}>
            {(item) => <StDropdownItem theme={props.theme} item={item} mount={props.mount} content={props.content} />}
          </For>
        </dm.Group>
      </Match>
    </Switch>
  );
};
